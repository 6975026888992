@import './shared/styles/_vuetify-overrides.scss';















































































































.cassie-dropdown {
	.v-select-list .v-list-item .v-list-item__action {
		margin: 5px 10px 0 0 !important;
	}

	&--small {
		.v-input__control .v-input__slot {
			height: 32px !important;
			padding: 0 8px !important;
			min-height: 0 !important;
		}

		.v-select-list .v-list-item {
			min-height: 0 !important;
		}

		.v-select-list .v-list-item .v-list-item__content {
			padding: 4px 0 !important;
		}

		.v-select__selection.v-select__selection {
			font-size: 85% !important;
			margin-left: 6px;
		}

		.v-input__append-inner {
			margin-top: 4px !important;
		}

		.v-input__control .v-input__slot label {
			margin-top: -12px !important;
		}

		.v-label {
			top: 18px !important;
			font-size: 14px !important;
		}

		.v-input__control .v-input__slot label.v-label--active {
			margin: 0 0 0 5px !important;
		}

		.v-label--active {
			-webkit-transform: translateY(-25px) scale(0.65) !important;
			transform: translateY(-25px) scale(0.65) !important;
		}
	}
}
